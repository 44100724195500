var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-main console"},[(_vm.$route.meta.type !== 'owner')?_c('OldHeader',{staticClass:"head-wrap",attrs:{"scene":"userHome","isWhiteBg":true,"isShowLogin":false}}):_vm._e(),(_vm.$route.meta.type === 'owner')?_c('pageTitle',{attrs:{"pageTitle":"个人主页"}}):_vm._e(),(_vm.$route.meta.type !== 'owner')?_c('div',{staticClass:"v-home-bg",style:({
      backgroundImage: `url(${
        _vm.userHomeVo && _vm.userHomeVo.img_url ? _vm.userHomeVo.img_url || _vm.static_img_url : _vm.static_img_url
      })`,
      backgroundSize: _vm.BgImgsSize[_vm.bgImgMode - 1]
    })}):_vm._e(),_c('div',{class:_vm.$route.meta.type !== 'owner'
        ? _vm.userHomeVo &&
          Number(_vm.userHomeVo.show_subject) === 0 &&
          Number(_vm.userHomeVo.show_webinar_list) === 0
          ? 'pc_bg no-creates'
          : 'pc_bg'
        : ''},[_c('div',{class:['user__layout--title', { 'ctrl-layout': _vm.$route.meta.type === 'owner' }]},[_c('ul',[_c('li',[_c('span',{staticClass:"image-contain"},[_c('img',{staticClass:"user__avatar",style:({
                objectFit: _vm.ImgsSize[_vm.imgMode - 1],
                objectPosition: _vm.imgMode == 2 ? 'left top' : ''
              }),attrs:{"src":_vm.userHomeVo && _vm.userHomeVo.homepage_avatar
                  ? _vm.userHomeVo.homepage_avatar || _vm.avatarImgUrl
                  : _vm.avatarImgUrl,"alt":""}})])]),_c('li',{class:`layout__center ${
            !(_vm.userHomeVo && Number(_vm.userHomeVo.show_share) === 1) ? 'one--btn' : ''
          }`},[_c('h1',[_vm._v(" "+_vm._s(_vm.userHomeVo && _vm.userHomeVo.title ? _vm.userHomeVo.title : '')+" ")]),_c('div',{ref:"intro",class:_vm.open_hide ? 'open_hide user__remark' : 'user__remark'},[_vm._v(" "+_vm._s(_vm.userHomeVo.content)+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showToggle && _vm.userHomeVo && _vm.userHomeVo.content),expression:"showToggle && userHomeVo && userHomeVo.content"}],staticClass:"user__show__btn",on:{"click":_vm.showBtnChange}},[_vm._v(" "+_vm._s(_vm.open_hide ? '展开' : '收起')+" "),_c('i',{class:_vm.open_hide ? 'vh-icon-arrow-down' : 'vh-icon-arrow-up'})])]),_c('li',{class:!(_vm.userHomeVo && Number(_vm.userHomeVo.show_share) === 1) ? 'one--btn' : ''},[(_vm.setHomeCheck)?_c('vh-button',{attrs:{"plain":"","size":"medium","round":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toHomeSetPage.apply(null, arguments)}}},[_vm._v(" 设置 ")]):_vm._e(),(_vm.userHomeVo && Number(_vm.userHomeVo.show_share) === 1)?_c('vh-button',{attrs:{"plain":"","size":"medium","round":""},on:{"click":function($event){return _vm.openDialog('share')}}},[_vm._v(" 分享 ")]):_vm._e()],1)])]),_c('div',{staticClass:"user__layout--main"},[(!_vm.isSetShow)?_c('home-main',{ref:"homeMain",on:{"showSet":_vm.showSetHandle}}):_vm._e()],1),_c('shareDialog',{ref:"share",attrs:{"baseInfo":{
        title: this.userHomeVo.title,
        intro: this.userHomeVo.content,
        pic: this.userHomeVo.homepage_avatar || this.avatarImgUrl
      }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }